import React from 'react'
import {EyeFill} from  '@styled-icons/bootstrap/EyeFill'
import styled from 'styled-components'
import { pColor } from '../../styles/variables'

const StyledEye = styled(EyeFill)`
    color: ${pColor};
    width: 24px;
    height: 24px;
    cursor: pointer;
`
export default function Eyes ({action, item}) { return <StyledEye onClick={ () => action(item)}/> }