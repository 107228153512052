import React from 'react'
import styled from 'styled-components'
import { UseUser } from '../../hooks/context/user'

const StyledContainer = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #fbfbfb;
    border-radius: 50%;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`
const StyledActivity = styled.span`
    width: 10px;
    height: 10px;
    display: block;
    background-color: #7ED321;
    position: absolute;
    bottom: 0;
    right: 3px;
    border: 1px solid #ffffff;
    border-radius: 50%;
`
export default function TopbarUser(props) {
  const { user } = UseUser()
  return (
    <StyledContainer onClick={ () => props.showMenu()}>
        <img alt="user" src={user.profile.picture} />
        <StyledActivity />
    </StyledContainer>
  );
}
