import styled from 'styled-components';

export const StyledTableContainer = styled.div`
    width: 100%;
    overflow: scroll;
`
export const StyledTable = styled.table`
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    th {
        color: #788195;
        font-size: 13px;
        background-color: #f1f3f6;
        font-weight: 500;
        text-align: left;
        border-bottom: 1px solid #f0f0f0;
        transition: background 0.3s ease;
        position: relative;
        padding: 16px 16px;
        overflow-wrap: break-word;
    }
    tbody tr:hover { td { background: #f8f8f8 } }
    td {
        font-size: 12px;
        color: #797979;
        border-bottom: 1px solid #e9e9e9;
        position: relative;
        padding: 16px 16px;
        overflow-wrap: break-word;
    }
`