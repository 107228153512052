import styled from "styled-components";
import { OpenInNew } from "@styled-icons/material-rounded/OpenInNew";
import { ChevronBottom } from "@styled-icons/open-iconic/ChevronBottom";
import { UserCircle } from "@styled-icons/boxicons-regular/UserCircle";
import { BarChartLine } from "@styled-icons/bootstrap/BarChartLine";
import { Contact } from "@styled-icons/boxicons-solid/Contact";
import { Game } from "@styled-icons/crypto/Game";
import { pColor, sColor } from "../../styles/variables";
import { Link } from "react-router-dom";
const color = `rgb(255, 255, 255)`;

const iconsMenu = `
    color: ${color};
    width: 19px;
    height: 19px;
    cursor: pointer;
`;
export const StyledContainer = styled.div`
  position: fixed;
  height: 100%;
  z-index: 50;
  overflow: auto;
  background-color: ${pColor};
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.2s;
  &.desktop-menu-open {
    max-width: 223px;
    min-width: 223px;
    width: 223px;
  }
  &.desktop-menu-close {
    max-width: 70px;
    min-width: 70px;
    width: 70px;
    .submenu {
      margin: 0;
      padding: 0px 16px;
    }
  }
  &.phone-menu-close {
    max-width: 0px;
    min-width: 0px;
    width: 0px;
  }
  &.phone-menu-open {
    max-width: 223px;
    min-width: 223px;
    width: 223px;
  }
`;

export const StyledLinkLogo = styled(Link)`
  background-color: ${sColor};
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  line-height: 50px;
  transition: min-width 0.5s;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 30px 0;
  background: transparent;
  li {
    position: relative;
    color: #fff;
    background-color: transparent;
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    &.minimize {
      button {
        width: 70px;
        display: flex;
        justify-content: center;
        span {
          display: none;
        }
      }
    }
    .submenu {
      list-style: none;
      background-color: ${sColor};
      box-shadow: none;
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.35s;
      &.nivel-two {
        padding-left: 20px;
      }
      a {
        padding: 0;
        span {
          margin: 0;
        }
      }
      button {
        padding-left: 0;
      }
      &.open {
        overflow: hidden;
        max-height: 800px;
        height: auto;
        transition: max-height 0.35s;
      }
    }
  }
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 400;
  width: auto;
  display: flex;
  align-items: center;
  padding-left: 15px;
  height: 40px;
  span {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    color: ${color};
  }
`;
export const StyledLinkCustom = styled.a`
  text-decoration: none;
  font-weight: 400;
  width: auto;
  display: flex;
  align-items: center;
  padding-left: 15px;
  height: 40px;
  span {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    color: ${color};
  }
`;
export const StyledBotton = styled.button`
  width: 100%;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-left: 15px;
  height: 40px;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: ${color};
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
`;

export const IconDispatcher = styled(OpenInNew)`
  ${iconsMenu}
`;
export const IconRatings = styled(BarChartLine)`
  ${iconsMenu}
`;
export const IconGroagenda = styled(Contact)`
  ${iconsMenu}
`;
export const IconCandict = styled(UserCircle)`
  ${iconsMenu}
`;
export const IconGame = styled(Game)`
  ${iconsMenu}
`;
export const IconOpen = styled(ChevronBottom)`
  position: absolute;
  right: 15px;
  top: 17px;
  color: ${color};
  width: 10px;
  height: 10px;
`;
