import React from 'react';
import styled from 'styled-components'
import { Notifications } from '@styled-icons/ionicons-sharp/Notifications'

const StyledContainer = styled.div`
    position: relative;
    line-height: normal;
    span {
        font-size: 10px;
        color: #fff;
        background-color: #3775F2;
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 20px;
        position: absolute;
        top: -8px;
        left: 10px;
        right: inherit;
        border-radius: 50%;
    }
`

const StyledIcon = styled(Notifications)`
    color: #ffd923;
    width: 24px;
    height: 24px;
    cursor: pointer;
`


export default function TopbarNotification({showNotify, number}) {
  return (
      <StyledContainer onClick={ () => showNotify()}>
        <span>{number}</span>
        <StyledIcon />
      </StyledContainer>
  );
}