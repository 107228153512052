import React from 'react';
import { useAlert } from '../../hooks/context/alert';

//components
import Close from '../icons/close';
import Done from '../icons/done';
import NotDone from '../icons/error';

import {StyledContainer, StyledHeader, StyledText} from './styles'

const AlertNotification = () => {

    const { alert, closeAlert }  = useAlert();

    return (
        <StyledContainer className={alert.active ? 'active' : 'not-active'}>
            {
                alert.type === "success" &&
                <>
                    <StyledHeader>
                        <div>
                            <Done />
                            <h1>{alert.title}</h1>
                        </div>
                        <Close close={closeAlert} />
                    </StyledHeader>
                    <StyledText>
                        <p>{alert.text}</p>
                    </StyledText>
                </>
            }
            {
                alert.type === 'error' &&
                <>
                    <StyledHeader>
                        <div>
                            <NotDone />
                            <h1>{alert.title}</h1>
                        </div>
                        <Close close={closeAlert} />
                    </StyledHeader>
                    <StyledText>
                        <p>{alert.text}</p>
                    </StyledText>
                </>
            }
        </StyledContainer>
    );
};

export default React.memo(AlertNotification, ()=> { return true } );