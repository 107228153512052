import React from "react";
import LoadingSmall from "../loadingSmall";
import styled from "styled-components";

export const StyledSelect = styled.select`
  padding: 0 10px;
  width: calc(100% - 20px);
  height: 35px;
  cursor: text;
  text-align: left;
  font-size: 13px;
  line-height: 1.5;
  color: #595959;
  background-color: #fff;
  background-image: none;
  border: 1px solid ${({ error }) => (error ? "#721c24" : "#e9e9e9 ")} !important;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-bottom: ${({ bottom }) => bottom};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Select = ({
  name,
  value,
  onChange,
  placeholder,
  error,
  bottom,
  onBlur,
  items,
  notvalue,
  loading,
}) => {
  return loading ? (
    <LoadingSmall />
  ) : (
    <StyledSelect
      bottom={bottom === null ? 0 : bottom}
      error={error}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
    >
      {placeholder && <option value="">{placeholder}</option>}
      {items.map((item, index) => {
        return notvalue ? (
          <option key={index} value={item}>
            {item}
          </option>
        ) : (
          <option key={index} value={item.id}>
            {item.name}
          </option>
        );
      })}
    </StyledSelect>
  );
};

export default Select;
