import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  label {
    color: #595959;
    font-size: 14px;
    width: 260px;
    @media (max-width: 400px) {
      width: 130px;
    }
  }
  .form-element-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    p {
      margin: 0px;
      font-size: 12px;
      color: #721c24;
    }
  }
`;
const FormElement = ({ children, label, error, errorMessage }) => {
  return (
    <StyledContainer>
      <label>{label}</label>
      <div className="form-element-content">
        {children}
        {error && <p className="error">{errorMessage}</p>}
      </div>
    </StyledContainer>
  );
};

export default FormElement;
