import React from 'react';

//styled
import {StyledTableContainer, StyledTable} from './styled'

const Table = ({ titles, children}) => {
    return (
        <StyledTableContainer>
            <StyledTable>
                <thead>
                    <tr>
                        { titles.map((item, index) => <th key={index}>{item}</th> )}
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </StyledTable>
        </StyledTableContainer>
    );
};

export default Table;