import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';

//components
import Layout from '../../components/layout'
import Loading from '../../components/loading';
import Table from '../../components/table';
import Title from '../../components/ui/title';
import { useNotification } from '../../hooks/context/notifaction';
import { getToken } from '../../libs/cookies';
import CustomLink from '../../components/ui/link'
import TwoCheck from  '../../components/icons/twoCheck'
import Check from '../../components/icons/check'
import Pagination from '../../components/pagination';

const StyledContainer = styled.div`
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
`
const StyledLink = styled.a`
    display: block;
    font-size: 13px;
    color: #595959;
    line-height: 1.1;
    text-decoration: none;
`

const Notification = () => {
    const { notification, getAllNotifications } = useNotification()
    const [ paginate, setPaginate ] = useState({limit: 25, offset: 0, current:1})
    const [ loading, setLoading ] = useState(false)
    const titles = ['Título','Contenido', 'Leído', 'Tiempo']
    const token = getToken()

    const getNots = async (limit, offset) => {
        setLoading(true)
        await getAllNotifications(token, limit, offset)
        setLoading(false)
    }
    
    useEffect(() => {
        getNots(paginate.limit, paginate.offset)
    }, [])

    const changeCurrent = (current) => { setPaginate({...paginate, current: current})}
    
    return (
       <Layout>
           <Title text="Notificaciones" />
           {
               loading ? <Loading /> :
               <StyledContainer>
                    <Table titles={titles}>
                        {
                            !isEmpty(notification.all) &&
                                notification.all.notifications.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {
                                                    item.url_external ?
                                                    <StyledLink href={item.url}>{item.title}</StyledLink>
                                                    :
                                                    <CustomLink route={item.url} text={item.title} />
                                                    
                                                }
                                            </td>
                                            <td>{item.body}</td>
                                            <td>{item.read ? <TwoCheck /> : <Check />}</td>
                                            <td>{item.time_ago}</td>
                                        </tr>
                                    )
                                })
                        }
                    </Table>
                </StyledContainer>
           }
           {
               notification.all && notification.all.total > paginate.limit && 
                <Pagination 
                    total={notification.all.total} 
                    action={getNots} 
                    current={paginate.current}
                    changeCurrent={changeCurrent}
                    limit={paginate.limit}
                    offset={paginate.offset}
                />
           }
       </Layout>
    );
};

export default Notification;