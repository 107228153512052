import React from "react";
import styled from "styled-components";
import { pColor, sColor } from "../../styles/variables";

const StyledButton = styled.button`
  width: ${({ fullWeight }) => (fullWeight ? "100%" : "fit-content")};
  height: 35px;
  border: 0;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  touch-action: manipulation;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  line-height: 1.5;
  padding: 0 25px;
  font-size: 14px;
  border-radius: 4px;
  height: 36px;
  user-select: none;
  position: relative;
  color: #fff;
  background-color: ${({ disabled }) => (disabled ? sColor : pColor)};
  border-color: #ffd923;
  outline: none;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  &:hover {
    background-color: ${sColor};
  }
`;

const Button = ({ action, children, fullWeight, disabled }) => {
  return (
    <StyledButton disabled={disabled} onClick={action} fullWeight={fullWeight}>
      {children}
    </StyledButton>
  );
};

export default Button;
