import React from "react";
import Close from "../icons/close";
import { StyledCard, StyledCardHeader, StyledCardBody } from "./styled";
const Card = ({
  isClosed,
  title,
  close,
  children,
  margin,
  heigth,
  textHeader,
  fullWeight,
  img,
}) => {
  return (
    <StyledCard margin={margin} height={heigth} fullWeight={fullWeight}>
      <StyledCardHeader>
        {img ? (
          <div className="card-league-header-container">
            <img className="card-img" src={img} alt="img-card" />
            <span>
              <p>{textHeader}</p>
            </span>
          </div>
        ) : (
          <h1>{title}</h1>
        )}
        {isClosed && <Close close={close} />}
      </StyledCardHeader>
      <StyledCardBody>{children}</StyledCardBody>
    </StyledCard>
  );
};

export default Card;
