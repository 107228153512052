import React from 'react'
import styled from 'styled-components'
import HelperPagination from '../../libs/helpers/pagination'

const StyledContainer = styled.div`
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        font-size: 12px;
        margin-right: 10px;
        margin: 0 0 10px 0;
        font-weight: 100;
        color: #797979;
    }
`
const Pagination = ({total, limit, offset, action, current, changeCurrent, text}) => {
    let items = offset

    const onChange = pageNumber => {
        if(parseInt(pageNumber) === 1) {
            items = 0
        } else {
            items = (parseInt(pageNumber) - 1) * limit
        }
        changeCurrent(pageNumber)
        action(limit, items)
    };

    return (
        <StyledContainer>
            <h1>Total {total} {text}</h1>
             <HelperPagination
                onChange={onChange}
                defaultCurrent={1}
                current={current}
                defaultPageSize={limit}
                total={total}
              />
        </StyledContainer>
    );
}

export default Pagination;
