import React from 'react';
import styled from 'styled-components';

const StyledLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    z-index: 2000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    img {
        width: 60px;
        height: auto;
    }
`


function Loading(props) {
    return (
        <StyledLoading>
            <img src="/static/loading.gif" alt="loading"  />
        </StyledLoading>
    );
}

export default Loading;