import React, { useReducer, useMemo } from 'react'
import { ACTIONS } from '../constants'
import axios from 'axios'
import { apiURL } from '../../libs/helpers/methods'
import { tokenExpired } from '../../actions'

const inititalState = {
    all: {},
    news: {},
    one: {}
}

function notificationReducer (state, action) {
    switch (action.type) {
        case ACTIONS.SET_NEWS_NOTIFICATIONS:
            return Object.assign({}, state, {
                news: action.payload,
              });
        case ACTIONS.SET_All_NOTIFICATIONS:
            return Object.assign({}, state, {
                all: action.payload,
            });
        default:
            return state;
    }
}

const NoticationContext = React.createContext();

export function NotificationProvider(props) {
    const [ notification, dispatch ] = useReducer( notificationReducer, inititalState )

    async function getNewsNotifications(token) {
        await axios.get(`${apiURL()}/api/notifications/new?token=${token}`).then(res => {
            dispatch({ type: ACTIONS.SET_NEWS_NOTIFICATIONS, payload: res.data.data })
        }).catch( err => {
            tokenExpired(err)
        })
    }

    async function getAllNotifications(token, limit, offset) {
        await axios.get(`${apiURL()}/api/notifications?token=${token}&limit=${limit}&offset=${offset}`).then(res => {
            dispatch({ type: ACTIONS.SET_All_NOTIFICATIONS, payload: res.data.data })
        }).catch( err => {
            tokenExpired(err)
        })
    }

    const value = useMemo(() => {
        return ({
            notification,
            getNewsNotifications,
            getAllNotifications,
            dispatch
        })
    }, [notification])

    return (
        <NoticationContext.Provider value={value} {...props} />
    );
}

export function useNotification() {
    const context = React.useContext(NoticationContext)
    if(!context) {
        throw new Error('useUser debe estar dentro del proveedor UserContext')
    }
    return context
}