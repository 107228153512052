import React from "react";
import styled from "styled-components";

const StyledTextarea = styled.textarea`
  padding: 0 10px;
  width: -webkit-fill-available;
  resize: none;
  height: 80px;
  cursor: text;
  text-align: left;
  font-size: 13px;
  line-height: 1.5;
  color: #595959;
  background-color: #fff;
  background-image: none;
  border: 1px solid ${({ error }) => (error ? "#721c24" : "#e9e9e9 ")};
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-bottom: ${({ bottom }) => bottom};
  font-family: "Roboto", sans-serif;
`;

const Textarea = ({ name, onChange, placeholder, bottom }) => {
  return (
    <StyledTextarea
      bottom={bottom === null ? 0 : bottom}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default Textarea;
