import styled from 'styled-components';
import { Pagination } from 'antd';
import WithDirection from './rtl';
import { pColor } from '../../styles/variables';

const StyledPagination = ComponentName => styled(ComponentName)`
  &.ant-pagination {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    li { margin-right: 8px; }
    .ant-pagination-item {
      display: inline-block;
      min-width: 32px;
      height: 32px;
      color: rgba(0, 0, 0, 0.85);
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      line-height: 32px;
      text-align: center;
      vertical-align: middle;
      list-style: none;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      transition: all 0.3s;
      &.ant-pagination-item-active {
        background-color: ${pColor};
        border-color: ${pColor};

        a { color: #ffffff; }

        &:hover {
          background-color: ${pColor};
          a { color: #ffffff; }
        }
      }

      &:hover {
        border-color: ${pColor};
        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      &:hover a { color: ${pColor}; }
    }
    .ant-pagination-total-text {
      margin: '3px 0 3px 8px';
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      display: inline-block;
      min-width: 32px;
      height: 32px;
      color: rgba(0, 0, 0, 0.85);
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      line-height: 32px;
      text-align: center;
      vertical-align: middle;
      list-style: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      cursor: pointer;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      .ant-pagination-item-container { position: relative; }
      &.ant-pagination-jump-next-custom-icon {
        .anticon-double-right.ant-pagination-item-link-icon {
          position: absolute;
          top: 11px;
          left: 11px;
          color: ${pColor};
          font-size: 12px;
          letter-spacing: -1px;
          opacity: 0;
          transition: all 0.2s;
        }
        .ant-pagination-item-ellipsis {
          color: ${pColor};
          opacity: 1;
          transition: all 0.2s;
        }
        &:hover {
          .anticon-double-right.ant-pagination-item-link-icon {
            opacity: 1;
          }
          .ant-pagination-item-ellipsis {
            opacity: 0;
          }
        }
      }
    }

    .ant-pagination-prev:hover,
    .ant-pagination-next:hover {
      border-color: ${pColor};
      a { color: ${pColor}; }
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      transform: 'rotate(180deg)';
      color: rgba(0, 0, 0, 0.85);
      border-color: #d9d9d9;
      display: block;
      width: 100%;
      height: 100%;
      font-size: 12px;
      text-align: center;
      background-color: #fff;
      border: 0;
      border-radius: 2px;
      cursor: pointer;
      outline: none;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .ant-pagination-disabled .ant-pagination-item-link {
      display: block;
      width: 100%;
      height: 100%;
      font-size: 12px;
      text-align: center;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      outline: none;
      transition: all 0.3s;
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      cursor: not-allowed;
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      &:after {
        transform: rotate(180deg) scale(0.66666667);
      }
    }

    &.ant-pagination-simple {
      .ant-pagination-prev,
      .ant-pagination-next {
        margin: 0;
      }

      .ant-pagination-simple-pager {
        margin: '3px 0 3px 8px';
      }
    }

    .ant-pagination-options {
      display: none;
      .ant-select .ant-select-selection.ant-select-selection--single {
        height: 28px;
        .ant-select-selection__rendered {
          line-height: 28px;
        }
      }

      .ant-pagination-options-size-changer {
        margin: '0 0 0 8px';
      }
    }
  }
`

const Paginations = StyledPagination(Pagination);
const isoPagination = WithDirection(Paginations);

export default isoPagination;
