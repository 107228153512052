import React from 'react';

//components
import Button from '../../components/ui/button';
import Input from '../../components/ui/input';

//styled
import {StyledInputContent} from "../login/styled";

const RecoveryPassword = ({ email, error, handleOnchange, handleSubmit, errorServer}) => {
    return (
        <form>
            <h1>¿Has olvidado la contraseña?</h1>
            <p>Escribe tu dirección de email y te enviaremos un email para restablecerla</p>
            <StyledInputContent>
            <Input 
                error={error || errorServer}
                type="email"
                name="email" 
                placeholder="Dirección de email"
                value={email}
                onChange={handleOnchange}
            />
            </StyledInputContent>
            <Button action={ handleSubmit } fullWeight>
                Enviar
            </Button>
        </form>
    );
};

export default RecoveryPassword;