import React from 'react'
import {Refresh} from  '@styled-icons/boxicons-regular/Refresh'
import styled from 'styled-components'

const StyledRefresh = styled(Refresh)`
    color: #fff;
    width: 24px;
    height: 24px;
    cursor: pointer;
`
export default function RefreshPage () { return <StyledRefresh /> }
    