import React from "react";
import Layout from "../../components/layout";
import Advisers from "./ advisers";
import Teams from "./teams";
const Dispatcher = ({ match }) => {
  return (
    <Layout>
      {match.url === "/dispatcher/equipos-hipotecas" && (
        <Teams type="mortgages" typeName="hipotecas" />
      )}
      {match.url === "/dispatcher/equipos-inmuebles" && (
        <Teams type="properties" typeName="inmuebles" />
      )}
      {match.url === "/dispatcher/assesores-hipotecas" && (
        <Advisers type="mortgages" typeName="hipotecas" />
      )}
      {match.url === "/dispatcher/assesores-inmuebles" && (
        <Advisers type="properties" typeName="inmuebles" />
      )}
    </Layout>
  );
};

export default Dispatcher;
