import React, { useReducer, useMemo, useState } from "react";
import { ACTIONS } from "../constants";
import axios from "axios";
import { removeToken, setToken } from "../../libs/cookies";
import { apiURL } from "../../libs/helpers/methods";
import { tokenExpired } from "../../actions";

const inititalState = {
  token: "",
  loggedNow: false,
  menu: {},
  profile: {},
};

function userReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_USER:
      return Object.assign({}, state, {
        profile: action.payload,
      });
    case ACTIONS.SET_MENU:
      return Object.assign({}, state, {
        menu: action.payload,
      });
    case ACTIONS.SET_TOKEN:
      return Object.assign({}, state, {
        token: action.payload,
        loggedNow: true,
      });
    default:
      return state;
  }
}

const UserContext = React.createContext();

export function UserProvider(props) {
  const [user, dispatch] = useReducer(userReducer, inititalState);
  const [loading, setLoading] = useState(false);
  let result = true;
  async function signIn(user) {
    setLoading(true);
    const { data } = await axios.post(`${apiURL()}/api/login`, user);
    if (data.meta.success) {
      dispatch({ type: ACTIONS.SET_TOKEN, payload: data.data.token });
      setToken(data.data.token);
    } else {
      result = false;
    }
    setLoading(false);
    return result;
  }

  async function getMe(token) {
    await axios
      .get(`${apiURL()}/api/users/me?token=${token}`)
      .then((res) => {
        dispatch({ type: ACTIONS.SET_USER, payload: res.data.data });
      })
      .catch((err) => {
        tokenExpired(err);
      });
  }

  async function getMenu(token) {
    await axios
      .get(`${apiURL()}/api/users/menu?token=${token}`)
      .then((res) => {
        dispatch({ type: ACTIONS.SET_MENU, payload: res.data.data });
      })
      .catch((err) => {
        tokenExpired(err);
      });
  }

  async function checkToken(token) {
    return await new Promise((response, error) => {
      axios
        .get(`${apiURL()}/api/check-token?token=${token}`)
        .then((res) => {
          response(res);
          dispatch({ type: ACTIONS.SET_TOKEN, payload: token });
        })
        .catch((err) => {
          error(err);
        });
    });
  }

  async function logout(token) {
    await axios
      .delete(`${apiURL()}/api/logout?token=${token}`)
      .then((res) => {
        removeToken();
      })
      .catch((err) => {
        removeToken();
      });
    window.location.href = "/login";
  }

  async function resetPasswordCheck(token) {
    return await axios.get(`${apiURL()}/api/reset_password/${token}`);
  }

  async function resetPassword(token, password) {
    return await axios.put(`${apiURL()}/api/reset_password/${token}`, {
      password,
    });
  }

  async function recoveryPassword(email) {
    let result = true;
    await axios
      .post(`${apiURL()}/api/reset_password`, { email })
      .then((res) => {
        result = true;
      })
      .catch((err) => {
        result = false;
      });
    return result;
  }

  const value = useMemo(() => {
    return {
      user,
      loading,
      signIn,
      getMe,
      getMenu,
      checkToken,
      logout,
      recoveryPassword,
      resetPasswordCheck,
      resetPassword,
    };
  }, [user, loading]);

  return <UserContext.Provider value={value} {...props} />;
}

export function UseUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUser debe estar dentro del proveedor UserContext");
  }
  return context;
}
