import React from 'react';

//components
import Done from '../../components/icons/done';
import NotDone from '../../components/icons/error';
import Eyes from '../../components/icons/eye';
import Table from '../../components/table';
import Loading from '../../components/loading';
import NotData from '../../components/notData';

//styles
import {StyledContainer} from './styled'

const Container = ({loading, leads, openMessage}) => {
    const tableTitles = [
        'Fecha',
        'Nombre',
        'Teléfono',
        'Email',
        'Dirección',
        'Procedencia',
        'Propietario',
        'Equipo',
        'Tipo',
        'Oportunidad',
        'Mensaje',
        'Candidato creado',
        'Notas'
    ]

    return (
        <StyledContainer>
            {
                loading ? <Loading /> : 
                <>
                {
                    leads.leads.length <= 0 ? <NotData /> :
                    <Table titles={tableTitles}>
                        {
                            leads.leads.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.created_at}</td>
                                        <td>{item.name}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.email}</td>
                                        <td>{item.address}</td>
                                        <td>{item.provenance}</td>
                                        <td><strong>{item.owner}</strong></td>
                                        <td><strong>{item.team}</strong></td>
                                        <td>{item.type}</td>
                                        <td>{item.opportunity_type} {item.property_reference}</td>
                                        <td>{item.message && <Eyes action={openMessage} item={item.message}/>}</td>
                                        <td>{item.crm.created ? <Done /> : <NotDone />}</td>
                                        <td>{ item.crm.errors.map(item => item)}</td>
                                    </tr>
                                )
                            })
                        }
                    </Table>
                }
                </>
            }
        </StyledContainer>
    );
};

export default Container;