import React from 'react'
import {DoneAll} from '@styled-icons/material/DoneAll'
import styled from 'styled-components'

const StyledTwoCheck = styled(DoneAll)`
    color: #2ea44f;
    width: 24px;
    height: 24px;
    cursor: pointer;
`
export default function RefreshPage () { return <StyledTwoCheck /> }