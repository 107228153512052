import React, { useState } from "react";

//components
import Button from "../../components/ui/button";

//styles
import {
  StyledFilterContainer,
  StyledFilterContent,
  StyledButtonContent,
} from "./styles";

const year = [
  { name: "2020", value: "2020" },
  { name: "2021", value: "2021" },
];
const month = [
  { name: "Enero", value: "01" },
  { name: "Febrero", value: "02" },
  { name: "Marzo", value: "03" },
  { name: "Abril", value: "04" },
  { name: "Mayo", value: "05" },
  { name: "Junio", value: "06" },
  { name: "Julio", value: "07" },
  { name: "Agosto", value: "08" },
  { name: "Septiembre", value: "09" },
  { name: "Octubre", value: "10" },
  { name: "Noviembre", value: "11" },
  { name: "Diciembre", value: "12" },
];
const Filters = ({ yearSelect, saveFilter, loading }) => {
  const [selectOption, setSelectOption] = useState({
    year: yearSelect,
    month: "",
  });

  function handleOnChange(e) {
    const { name, value } = e.target;
    setSelectOption({ ...selectOption, [name]: value });
  }

  function goFilter() {
    const data =
      selectOption.month !== ""
        ? selectOption.year + "/" + selectOption.month
        : selectOption.year;
    const dataText =
      selectOption.month !== ""
        ? monthText(selectOption.month) + " de " + selectOption.year
        : selectOption.year;
    saveFilter(data, dataText);
  }

  function monthText(value) {
    let text = "";
    month.map((item) => {
      if (item.value === value) text = item.name;
    });
    return text;
  }

  return (
    <StyledFilterContainer margin="0px 0px 20px 0px">
      <StyledFilterContent>
        <div className="filter-content">
          <select onChange={handleOnChange} name="year">
            {year.map((item, index) => (
              <option
                key={index}
                value={item.value}
                selected={parseInt(item.name) === yearSelect}
              >
                {item.name}
              </option>
            ))}
          </select>
          <select onChange={handleOnChange} name="month">
            <option value="">Todos</option>
            {month.map((item, index) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </StyledFilterContent>
      <StyledButtonContent size="20px">
        <Button disabled={loading} action={goFilter}>
          Filtrar
        </Button>
      </StyledButtonContent>
    </StyledFilterContainer>
  );
};

export default Filters;
