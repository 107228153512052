import { isEmpty } from "lodash";
import React from "react";

//component
import Layout from "../../components/layout";
import Title from "../../components/ui/title";
import { UseUser } from "../../hooks/context/user";
import Rating from "./Rating";

function League() {
  const { user } = UseUser();

  return (
    <Layout>
      <Title text="Ligas" />
      {!isEmpty(user.menu) &&
        !isEmpty(user.menu.grogames) &&
        !isEmpty(user.menu.grogames.leagues) &&
        user.menu.grogames.leagues.length > 0 && (
          <Rating permitions={user.menu.grogames.leagues} />
        )}
    </Layout>
  );
}

export default League;
