import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

//components
import Login from "./pages/login";
import Home from "./pages/home";
import Profile from "./pages/profile";
import RecoveryPassword from "./pages/recoveryPassword";
import ResetPassword from "./pages/resetPassword";
import Dispatcher from "./pages/dispatcher";
import Lead from "./pages/lead";
import NotFound from "./pages/errorPages/404";
import ErrorServer from "./pages/errorPages/500";
import Notification from "./pages/notification";
import HearBeat from "./pages/hearbeat";
import League from "./pages/groGames/league";

//context
import { UserProvider } from "./hooks/context/user";
import { NotificationProvider } from "./hooks/context/notifaction";
import { AlertProvider } from "./hooks/context/alert";

function App() {
  return (
    <UserProvider>
      <NotificationProvider>
        <AlertProvider>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/notification" component={Notification} />
              <Route
                exact
                path="/recovery_password"
                component={RecoveryPassword}
              />
              <Route exact path="/grogames/leagues" component={League} />
              <Route
                exact
                path="/password_reset/:token"
                component={ResetPassword}
              />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/leads" component={Lead} />
              <Route exact path="/hearbeat" component={HearBeat} />
              <Route exact path="/dispatcher/:type" component={Dispatcher} />
              <Route exact path="/500" component={ErrorServer} />
              <Route component={NotFound} />
            </Switch>
          </BrowserRouter>
        </AlertProvider>
      </NotificationProvider>
    </UserProvider>
  );
}

export default App;
