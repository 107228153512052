import React from 'react';
import styled from 'styled-components'

const StyledContainer = styled.div`
    margin-bottom: 15px;
    padding: 10px;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    .text {
        margin: 5px;
        font-size: 12px;
    }
`
const AlertSuccess = ({text}) => {
    return (
        <StyledContainer>
            <p className="text">{text}</p>
        </StyledContainer>
    );
};

export default AlertSuccess;