import React from 'react';

//components
import Layout from '../../components/layout'
import { UseUser } from '../../hooks/context/user';

//styles
import {
    StyledContainer,
    StyledImg,
    StyledContentOne,
    StyledContentTwo
} from './styles'

const index = () => {
    const { user } = UseUser()
    return (
        <Layout>
            <StyledContainer>
                <StyledImg urlImg={user.profile.picture} />
                <StyledContentOne>
                    <h1>{user.profile.first_name} {user.profile.last_name}</h1>
                    <h2>{user.profile.title}</h2>
                </StyledContentOne>
                <StyledContentTwo>
                    <div>
                        <h2>Email</h2>
                        <p>{user.profile.email}</p>
                    </div>
                    <div>
                        <h2>Equipo</h2>
                        <p>{user.profile.team}</p>
                    </div>
                </StyledContentTwo>
            </StyledContainer>
        </Layout>
    );
};

export default index;