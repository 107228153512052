import styled from 'styled-components';

export const StyledLayoutContainer = styled.div`
    position: relative;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;
    height: auto;
`
export const StyledBodyContainer = styled.div`
    background-color: #ecf0f5;
    display: flex;
    flex: 1 1;
`

export const StyledMainContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: auto;
    padding: 90px 20px 20px 20px;
`

export const StyledHiddenSidebar = styled.div`
    height: 100%;
    transition: all 0.2s;
    &.desktop-menu-open {
        max-width: 223px;
        min-width: 223px;
        width: 223px;
   }
   &.desktop-menu-close {
        max-width: 70px;
        min-width: 70px;
        width: 70px;
   }
   @media (max-width: 700px) { 
        max-width: 0px;
        min-width: 0px;
        width: 0px;
    }
`
