import React from 'react'
import {Menu}  from  '@styled-icons/boxicons-regular/Menu'
import styled from 'styled-components'
import { pColor } from '../../styles/variables'

const StyledMenu = styled(Menu)`
    color: ${pColor};
    width: 30px;
    height: 30px;
    cursor: pointer;
`
export default function Hamburguer ({open}) { return <StyledMenu onClick={ () => open()} /> }
    