import React, { useState } from 'react'

const AlertContext = React.createContext();
let setTime = null

export function AlertProvider(props) {
    const [ alert, setAlert ] = useState({ active: false, type: "", title: '', text: '' })

    async function openAlert(type, title, text) {
        await setAlert(
            { 
                active: true, 
                type: type,
                title: title,
                text: text
            }
        )
        if(setTime === null) {
            setTime = setTimeout(function(){ 
                closeAlert() 
            }, 5000);
        } else {
            clearInterval(setTime)
            setTime = setTimeout(function(){ 
                closeAlert() 
            }, 5000);
        }
     }

     async function closeAlert () {
         setTime = null
        await setAlert(
            { 
                active: false, 
                type: '',
                title: '',
                text: ''
            }
        )
     }

    return (
        <AlertContext.Provider value={
            {
                alert,
                openAlert,
                closeAlert
            }
        } {...props} />
    );
}

export function useAlert() {
    const context = React.useContext(AlertContext)
    if(!context) {
        throw new Error('useAlert debe estar dentro del proveedor UserContext')
    }
    return context
}