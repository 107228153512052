import styled from 'styled-components'

export const comunStyled = `
    color: #788195;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
`
export const StyledContainer =  styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px;
    @media (max-width: 500px) {
        width: inherit;
    }

`
export const StyledTextContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    @media (max-width: 500px) {
        align-items: center ;
    }
    h1 {
        font-size: 84px;
        font-weight: 700;
        margin: 0 0 25px;
        ${comunStyled}
    }
    h3 {
        font-size: 24px;
        font-weight: 400;
        margin: 0 0 15px;
        ${comunStyled}
    }
    p {
        font-size: 14px;
        font-weight: 400;
        color: #797979;
        margin: 0 0 15px;
    }
`
export const StyledImgContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
    margin-right: inherti;
    height: 500px;
    @media (max-width: 500px) {
        margin: 0;
    }
    img { max-height: 100%; }
`