import styled from "styled-components";

export const StyledCard = styled.div`
  position: relative;
  width: ${({ fullWeight }) => (fullWeight ? "100% " : "400px")};
  margin: ${({ margin }) => (margin ? "120px 0px 0px 0px" : "0px")};
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  height: ${({ height }) => (height ? "fit-content" : "auto")};
`;
export const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
  padding: 10px 20px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid #e9e9e9;
  background-color: #f3f3f3;
  .card-img {
    width: 120px;
    height: auto;
  }
  h1 {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }
  .card-league-header-container {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
      p {
        margin: 0;
      }
    }
  }
`;

export const StyledCardBody = styled.div`
  padding: 20px;
  max-height: 700px;
  overflow-x: scroll;
`;
