import React from 'react';
import styled from 'styled-components'

const StyledContainer = styled.div`
    margin-bottom: 15px;
    padding: 10px;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    .text {
        margin: 5px;
        font-size: 12px;
    }
`

const AlertError = ({ text }) => {
    return (
        <StyledContainer>
            <p className="text">{text}</p>
        </StyledContainer>
    );
};

export default AlertError;