import styled from 'styled-components';

export const StyledContainer = styled.div`
    position: relative;
    width: calc(100% - 40px);
    padding-top: 70px;
    margin: 70px auto 30px;
    background-color: #fff;
    box-shadow: 0 2px 6px -2px rgba(0,0,0,0.26);
`
export const StyledImg = styled.div`
    display: false;
    position: absolute;
    margin: false;
    top: -70px;
    left: 50%;
    right: false;
    bottom: false;
    transform: translateX(-50%);
    height: 120px;
    width: 120px;
    border: 10px solid #fff;
    border-radius: 100%;
    background: url(${({urlImg}) => urlImg }) center no-repeat;
    background-size: cover;
`
export const StyledContentOne = styled.div`
    margin: 0;
    padding: 10px;
    text-align: center;
    h1 {
        margin: 0;
        font-size: 20px;
        letter-spacing: 3px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 5px;
    }
    h2 {
        letter-spacing: 1px;
        color: #999;
        font-weight: 400;
        font-size: 18px;
        margin: 0;
    }
`
export const StyledContentTwo = styled.div`
    display: flex;
    justify-content: center;
    min-height: 70px;
    padding: 10px 0px;
    text-align: center;
    overflow: hidden;
    div { 
        margin: 0 20px;
        h2 {
            font-size: 18px;
            letter-spacing: 2px;
            font-weight: 400;
            line-height: 24px;
            margin: 0;
        }
        P {
            font-size: 14px;
            letter-spacing: 2px;
            font-weight: 400;
            line-height: 24px;
            margin: 0;
            color: #999;
        }
    }
`