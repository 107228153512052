import React from 'react';
import styled from 'styled-components';

const StyledImg = styled.img`
    width: 100%;
    height: 35px;
    object-fit: contain;
`
function LoadingSmall(props) {
    return (
        <StyledImg src="/static/load.gif" alt="gif" />
    );
}

export default LoadingSmall;