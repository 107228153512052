import React from 'react';
import Button from '../../components/ui/button';
import {StyledContainer, StyledTextContent, StyledImgContent } from './styles'

const ErrorServer = ({history}) => {

    const goTo = () => history.push('/')

    return (
        <StyledContainer>
            <StyledTextContent>
                <h1>500</h1>
                <h3>Error de servidor interno</h3>
                <p>Algo salió mal. Por favor, inténtelo de nuevo más tarde.</p>
                <Button action={goTo}> Volver al inicio</Button>
            </StyledTextContent>
            <StyledImgContent>
                <img src="./static/rob.png"  alt="notfound"/>
            </StyledImgContent>
        </StyledContainer>
    );
};

export default ErrorServer;